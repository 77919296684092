import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { useWindowWidth } from "@react-hook/window-size";

import content from "../content";
import Page from "./Page";

const PageContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

const PageBase = styled.div`
    position: fixed;
    width: 50%;
    height: 100%;
    overflow: auto;
    padding: 0 1rem 5rem;
`;

const PageLeft = styled(PageBase)`
    left: 0;
    ::-webkit-scrollbar {
        display: none;
    }
`;
const PageRight = styled(PageBase)`
    right: 0;
`;

const MobilePages = styled.div`
    margin-top: 0rem;
`;

export default () => {
    const { id } = useParams();

    const [data, setData] = useState([]);
    const [currentScroll, setCurrentScroll] = useState(false);
    const leftPage = useRef();
    const rightPage = useRef();
    const width = useWindowWidth();

    useEffect(() => {
        if (
            id &&
            rightPage.current &&
            rightPage.current.childNodes &&
            rightPage.current.childNodes[id]
        ) {
            if (leftPage.current) {
                leftPage.current.childNodes[id].scrollIntoView();
            }
            rightPage.current.childNodes[id].scrollIntoView();
        }
        console.log("id changed", id);
    }, [id, data]);

    useEffect(() => {
        const getData = async () => {
            const data = await Promise.all(
                content.map(async (contentItem) => {
                    const left = await fetch(contentItem.left);
                    const right = await fetch(contentItem.right);
                    const leftText = await left.text();
                    const rightText = await right.text();
                    return {
                        ...contentItem,
                        leftText,
                        rightText,
                    };
                })
            );
            setData(data);
        };
        getData();
    }, []);

    const scrollFunction = (e, thisPage, thatPage) => {
        let elementInView = 0;
        let percentageScroll = 0;
        const scrollPoint = e.target.scrollTop;

        [...thisPage.current.children].reduce(
            (currentValue, el, currentIndex) => {
                if (currentValue <= scrollPoint) {
                    elementInView = currentIndex;
                    percentageScroll =
                        (scrollPoint - currentValue) / el.offsetHeight;
                }
                return currentValue + el.offsetHeight;
            },
            0
        );

        const thatHandScrollTop = [...thatPage.current.children][elementInView]
            .offsetTop;

        const thatHandAddition =
            [...thatPage.current.children][elementInView].offsetHeight *
            percentageScroll;

        thatPage.current.scrollTo({
            top: thatHandScrollTop + thatHandAddition,
        });
    };

    return width > 840 ? (
        <PageContainer>
            <PageLeft
                onScroll={(e) =>
                    currentScroll === "left" &&
                    scrollFunction(e, leftPage, rightPage)
                }
                onMouseOver={(e) => setCurrentScroll("left")}
                ref={leftPage}
            >
                {data.map((page) => (
                    <Page
                        content={page}
                        currentScroll={currentScroll === "left"}
                        id={id && Number(id)}
                        key={page.title}
                        side="left"
                    />
                ))}
            </PageLeft>
            <PageRight
                onScroll={(e) =>
                    currentScroll === "right" &&
                    scrollFunction(e, rightPage, leftPage)
                }
                onMouseOver={(e) => setCurrentScroll("right")}
                ref={rightPage}
            >
                {data.map((page) => (
                    <Page
                        content={page}
                        currentScroll={currentScroll === "right"}
                        id={id && Number(id)}
                        isFirstPage={page.title === "Introduction"}
                        key={page.title}
                        side="right"
                    />
                ))}
            </PageRight>
        </PageContainer>
    ) : (
        <MobilePages ref={rightPage}>
            {data.map((page, index) => (
                <div key={page.title}>
                    <Page content={page} id={id && Number(id)} side="right" />
                    {index !== 0 && (
                        <Page
                            content={page}
                            id={id && Number(id)}
                            side="left"
                        />
                    )}
                </div>
            ))}
        </MobilePages>
    );
};
