import React from "react";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";
import { MenuInsideContent } from "./Menu";

const Page = styled.div`
    padding: 9vh 1rem;
    min-height: 100vh;
    border-bottom: 1px solid #bbb;

    &:last-child {
        border-bottom: 0;
        padding-bottom: 50vh;
    }

    &:not(:first-child) {
        padding: ${(props) =>
            props.side === "left" ? "20vh 1rem" : "25vh 1rem"};
    }

    &:nth-child(2) {
        padding-top: 15vh;
    }

    @media screen and (max-width: 840px) {
        border-bottom: 0;
        min-height: auto;
        padding-top: 4rem !important;
        padding-bottom: 2rem !important;
    }
`;

const PageImage = styled.img`
    width: 100%;
    height: 80vh;
    margin-bottom: 5vh;
    object-fit: contain;
    padding: 0rem;

    @media screen and (max-width: 840px) {
        height: auto;
        object-fit: contain;
        margin-bottom: 0;
    }
`;

const PageText = styled.div`

    padding: ${props => props.isFirstPage ? 0 : "0 1rem"};

    @media screen and (max-width: 840px) {
        padding: 0;
    }

    p {
        max-width: 30rem;
        margin-left: auto;
        margin-right: auto;
    }
`;

export default ({ content, side, isFirstPage, thisIndex, ...props }) => {
    return (
        <Page side={side} {...props}>
            {side === "left" ? (
                <>
                    {content.scans.map((img) => (
                        <PageImage alt={content.title} src={img} key={img} />
                    ))}
                </>
            ) : (
                <>
                    <PageText isFirstPage={isFirstPage}>
                        <ReactMarkdown
                            escapeHtml={false}
                            source={
                                content[
                                    side === "left" ? "leftText" : "rightText"
                                ]
                            }
                        />
                    </PageText>
                    {thisIndex}
                    {isFirstPage && (
                        <MenuInsideContent
                            title={"Table of Contents"}
                            style={{
                                marginTop: "3rem",
                                marginLeft: "auto",
                                marginRight: "auto",
                            }}
                        />
                    )}
                </>
            )}
        </Page>
    );
};
