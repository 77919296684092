import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`

    html {
        font-size: calc(60% + 0.6vw); /* 21px */
        line-height: 1.3125;
        font-family: "louize-font";
        color: #333;
        @media screen and (max-width: 840px) {
            font-size: 110%;
        }
    }

    body {
        margin: 0;
    }

    * {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        box-sizing: border-box;

        ::selection {
            background: #e6d1b5;
        }
    }
    
    a {
        text-decoration: none;
        color: black;
    }

    h1,h2,h3 {
        margin-bottom: 0.5rem;
    }

    h1 {
        font-size: 2.2857rem;
        line-height: 1.0833333333;
        letter-spacing:-0.5px;
        text-align: center;
        font-weight: 400;
    }

    h1.poem {
        font-size: 1.75rem;
        letter-spacing: 2px;
        text-transform: uppercase;
    }

    h2 {
        font-size: 1.375rem;
        line-height: 1.2727272727;
        font-style: italic;
        text-align:center;
        font-variant-caps: small-caps;
        text-transform: lowercase;
        font-feature-settings: "smcp";
        letter-spacing: 2px;
        font-weight: 400;
        margin-bottom: 12.5vh;
    }

    nav {
        font-size: 1.375rem;
        line-height: 1.2727272727;
    }

    .full-right-center {
        width: 100%;
        position:absolute;
        top: 0;
        left: 0;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2.2857rem;
    }

    .page-image {
        width: 100%;
        height: auto;
        margin-bottom: 2.2857rem;
    }

    .isa-para {
        display: block;
        max-width:30rem;
        margin: 0 auto 2rem;
    }

    .right {
        text-align: right;
        letter-spacing: 1px;
    }
    
    .small-caps {
        font-variant-caps: small-caps;
        text-transform: lowercase;
        font-feature-settings: "smcp";
    }

    .two-columns {
        column-count: 2;
        column-gap: 2rem;
        margin-bottom: 4rem;

        p {
            margin: 0;
            text-indent: 2rem;
            &:first-child { text-indent: 0; }
        }
    }

    p {
        margin: 0rem 0 1rem;
    }

    p a {
        text-decoration: underline;
        text-decoration-color: rgba(0,0,0,0.2);
    }

    p.indent-first {
        margin: 0 auto;
    }
    p.indent {
        margin: 0 auto;
        text-indent: 5rem;
    }
    p.blockquote {
        margin: 2rem auto;
        padding-left: 5rem;
    }

    p.yair1 {
        margin: 0 auto;
    }

    p.yair2 {
        margin: 0 auto;
        max-width:28rem;
        padding-left: 1rem;
    }

    sup, .sup {
        font-size:0.75em;
        position: relative;
        top:-0.25em;
        margin:0 0.25em;
    }

    .footnote {
        font-size: 0.8rem;
        :first-of-type {
            margin-top: 3rem;
        }
    }

    .mode-cover {
        width: 100%; 
        height: 80vh; 
        margin-bottom: 5vh;
        object-fit: contain; 
        padding: 0rem;
        @media screen and (max-width: 840px) {
            height: auto;
            margin-bottom: 2rem;
        }
    }
    .mobile-only {
        display: block;
        @media screen and (min-width:841px) {
            display:none;
        }
    }

    .com-image {
        max-height: 4rem;
        width: auto;
        margin: 2rem 0;
    }

    .h1-super {
        font-size: 0.75rem;
        letter-spacing: 0px;
        display: inline-block;
        max-width: 12em;
        text-align: left;
        padding: 0.5rem;
    }
    .centre {
        text-align: center;
    }
    .small {
        font-size: 0.8rem;
    }

    .roguerie {
        margin-bottom:0.5rem;
    }

    .roguerie.ind {
        padding-left: 5rem;
    }

    .femke {
        display: flex;
        margin-bottom: 1rem;
        padding: 0 1rem;

        > div:first-child {
            padding-left: 2rem;
            flex: 1 1 75%;
            padding-right: 1.5rem;
        }

        &.ital {
            > div:first-child {
                font-style: italic;
                padding-left: 4rem;
                em {
                    font-style:normal;
                }
            }
        }

        &.caps {
            > div:first-child {
                padding-left: 0rem;
                font-variant-caps: small-caps;
                text-transform: lowercase;
                font-feature-settings: "smcp";
                letter-spacing: 1px;
                font-size: 1.1rem;
                line-height: 1.4rem;
            }
        }

        > div:last-child {
            padding-top:0.25rem;
            font-size: 0.8rem;
            flex: 1 1 25%;
        }

        @media screen and (min-width:841px) {
            &:hover { 
                > div:last-child {
                    opacity: 1;
                }
            }
            > div:last-child {
                opacity: 0;
                transition: all 0.1s ease; 
            }
        }
    }
`;
