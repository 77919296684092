import React, { useState } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

import content from "../content";
import MenuDots from "./MenuDots.svg";

const Container = styled.div`
    @media screen and (min-width: 841px) {
        &::before {
            content: "";
            height: 2rem;
            width: 1px;
            position: fixed;
            top: 0;
            left: 50%;
            background: #bbb;
            z-index: -1;
        }

        &::after {
            content: "";
            height: calc(100% - 4rem);
            width: 1px;
            position: fixed;
            top: 4rem;
            left: 50%;
            background: #bbb;
            z-index: -1;
        }
    }

    user-select: none;
`;

const Menu = styled.div`
    display: flex;
    justify-content: center;
    position: fixed;
    left: 0;
    width: 100%;
    top: 1.2rem;
    z-index: 2;

    @media screen and (max-width: 840px) {
        background: white;
        top: 0;
        width: 100%;
        overflow: auto;
        display: block;
        max-height: 100%;
    }
`;

const MenuInside = styled.div`
    padding: 1rem;
    border: 1px solid #444;
    background: white;
    z-index: 3;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 800px;
    @media screen and (max-width: 840px) {
        border: 0;
        border-bottom: 1px solid #eee;
        padding: 0.55rem 1rem 1rem;
    }
`;

const ClosedContainer = styled.div`
    display: flex;
    width: 100%;
    position: fixed;
    top: 1.89rem;
    left: 0;
    align-items: center;
    justify-content: center;
    height: 2rem;

    @media screen and (max-width: 840px) {
        background: white;
        top: 0;
        width: 100%;
        height: 2.5rem;
        border-bottom: 1px solid #eee;
    }
`;

const OpenMenuLink = styled.div`
    cursor: pointer;
    text-align: center;
`;

const SmallCaps = styled.span`
    font-variant-caps: small-caps;
    text-transform: lowercase;
    font-feature-settings: "smcp";
`;

const MenuItems = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
`;

const NavStyleLink = styled(NavLink)`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 0.125rem;
    &.selected {
    }

    @media screen and (max-width: 840px) {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0.5rem;
    }
`;

const ItemName = styled.div`
    font-variant-caps: small-caps;
    font-feature-settings: "smcp";
    letter-spacing: 0.333px;
`;

const ItemDots = styled.div`
    flex: 1;
    border-bottom: 8px solid transparent;
    border-image-source: url(${MenuDots});
    border-image-slice: 33% 33%;
    border-image-repeat: round;
    margin: 0 0.3333rem;
    position: relative;
    top: -6px;

    @media screen and (max-width: 840px) {
        display: none;
    }
`;

const ItemAuthor = styled.div`
    font-style: italic;
    padding-right: 1rem;
`;
const ItemPage = styled.div`
    font-variant-numeric: lining-nums;
    width: 3rem;
    @media screen and (max-width: 840px) {
        display: none;
    }
`;

const MenuUnderlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: ${(props) => (props.isShown ? "blur(7px)" : "blur(0px)")};
    /* transition: backdrop-filter 0.2s ease-out; */
    opacity: ${(props) => (props.isShown ? "1" : "0")};
    pointer-events: ${(props) => (props.isShown ? "inherit" : "none")};
    z-index: 1;
`;

export const MenuInsideContent = ({ title, style }) => {
    const contentFull = title === "Mode and Mode" ? content : content.slice(1);
    return (
        <MenuInside style={style}>
            <OpenMenuLink>
                <SmallCaps>{title}</SmallCaps>
            </OpenMenuLink>
            <MenuItems>
                {contentFull.map((item) => (
                    <NavStyleLink
                        key={item.title}
                        activeClassName="selected"
                        to={`/page/${item.pieceNumber}`}
                    >
                        <ItemName>{item.title}</ItemName>
                        <ItemDots />
                        <ItemAuthor>{item.author}</ItemAuthor>
                        <ItemPage>Page {item.pieceNumber}</ItemPage>
                    </NavStyleLink>
                ))}
            </MenuItems>
        </MenuInside>
    );
};

export const MenuInsideContentDefault = () => {};

export default () => {
    const [menuOpen, setMenuOpen] = useState(false);
    return (
        <Container>
            {menuOpen ? (
                <>
                    <Menu onClick={() => setMenuOpen(false)}>
                        <MenuInsideContent title={"Mode and Mode"} />
                    </Menu>
                </>
            ) : (
                <ClosedContainer>
                    <OpenMenuLink onClick={() => setMenuOpen(true)}>
                        <SmallCaps>Mode and Mode</SmallCaps>
                    </OpenMenuLink>
                </ClosedContainer>
            )}
            <MenuUnderlay
                onClick={() => setMenuOpen(false)}
                isShown={menuOpen}
            />
        </Container>
    );
};
