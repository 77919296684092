import Left1 from "Content/Left/1.md";
import Left2 from "Content/Left/2.md";
import Left3 from "Content/Left/3.md";
import Left4 from "Content/Left/4.md";
import Left5 from "Content/Left/5.md";
import Left6 from "Content/Left/6.md";
import Left7 from "Content/Left/7.md";
import Left8 from "Content/Left/8.md";
import Left9 from "Content/Left/9.md";
import Home from "Content/Right/Home.md";
import Right1 from "Content/Right/1.md";
import Right2 from "Content/Right/2.md";
import Right3 from "Content/Right/3.md";
import Right4 from "Content/Right/4.md";
import Right5 from "Content/Right/5.md";
import Right6 from "Content/Right/6.md";
import Right7 from "Content/Right/7.md";
import Right8 from "Content/Right/8.md";
import Right9 from "Content/Right/9.md";
import Right10 from "Content/Right/10.md";

import Scan01 from "Content/Left/0.1.jpeg";
import Scan11 from "Content/Left/1.1.jpeg";
import Scan21 from "Content/Left/2.1.jpeg";
import Scan22 from "Content/Left/2.2.jpeg";
import Scan23 from "Content/Left/2.3.jpeg";
import Scan31 from "Content/Left/3.1.jpeg";
import Scan41 from "Content/Left/4.1.jpeg";
import Scan42 from "Content/Left/4.2.jpeg";
import Scan43 from "Content/Left/4.3.jpeg";
import Scan44 from "Content/Left/4.4.jpeg";
import Scan51 from "Content/Left/5.1.jpeg";
import Scan61 from "Content/Left/6.1.jpeg";
import Scan62 from "Content/Left/6.2.jpeg";
import Scan71 from "Content/Left/7.1.jpeg";
import Scan81 from "Content/Left/8.1.jpeg";
import Scan82 from "Content/Left/8.2.jpeg";
import Scan91 from "Content/Left/9.1.jpeg";
import Scan92 from "Content/Left/9.2.jpeg";
import Scan93 from "Content/Left/9.3.jpeg";

export default [
    {
        pieceNumber: 0,
        title: "Introduction",
        author: "",
        rogueAuthor: "",
        left: null,
        scans: [Scan01],
        right: Home,
    },
    {
        pieceNumber: 1,
        title: "To the Letter",
        author: "Isabelle Sully",
        rogueAuthor: "Allen Norton",
        left: Left1,
        scans: [Scan11],
        right: Right1,
    },
    {
        pieceNumber: 2,
        title: "Something Had to Be Done [...]",
        author: "Femke de Vries",
        rogueAuthor: "",
        left: Left2,
        scans: [Scan21, Scan22, Scan23],
        right: Right2,
    },
    {
        pieceNumber: 3,
        title: "Japanese Maidens Go to Nurse Allies",
        author: "Mode and Mode",
        rogueAuthor: "Witter Bynner and decoration by Locher",
        left: Left3,
        scans: [Scan31],
        right: Right3,
    },
    {
        pieceNumber: 4,
        title: "Approximate Form Demo Beraisa",
        author: "Yair Oelbaum",
        rogueAuthor: "Frank Harris",
        left: Left4,
        scans: [Scan41, Scan42, Scan43, Scan44],
        right: Right4,
    },
    {
        pieceNumber: 5,
        title: "Questions Looming",
        author: "Rowan McNaught",
        rogueAuthor: "Wallace Stevens",
        left: Left5,
        scans: [Scan51],
        right: Right5,
    },
    {
        pieceNumber: 6,
        title: "Aux Galeries Lafayette",
        author: "Astrid Lorange",
        rogueAuthor: "Gertrude Stein",
        left: Left6,
        scans: [Scan61, Scan62],
        right: Right6,
    },
    {
        pieceNumber: 7,
        title: "You Don’t Need Pants",
        author: "Katherine Bernard",
        rogueAuthor: "Homer Croy",
        left: Left7,
        scans: [Scan71],
        right: Right7,
    },
    {
        pieceNumber: 8,
        title: "Dame Not Dada",
        author: "Ulrich Lehmann",
        rogueAuthor: "Dame Rogue",
        left: Left8,
        scans: [Scan81, Scan82],
        right: Right8,
    },
    {
        pieceNumber: 9,
        title: "Rogueries",
        author: "Matthew Linde",
        rogueAuthor: "Allen Norton",
        left: Left9,
        scans: [Scan91],
        right: Right9,
    },
    {
        pieceNumber: 10,
        title: "Colophon",
        author: "",
        rogueAuthor: "",
        left: Left9,
        scans: [Scan92, Scan93],
        right: Right10,
    },
];
