import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Menu from "Components/Menu";
import GlobalStyle from "Components/BaseStyle";

import Pages from "Components/Pages";

import "./fonts/style.css";

function App() {
    return (
        <>
            <Router>
                <Switch>
                    <Route path="/" exact>
                        <Pages />
                    </Route>
                    <Route path="/colophon">Colophon</Route>
                    <Route path="/page/:id">
                        <Pages />
                    </Route>
                </Switch>
                <Menu />
                <GlobalStyle />
            </Router>
        </>
    );
}

export default App;
